import parselLogo from "@/public/parsel_logo_black.png";
import Image from "next/image";
import MetaPixelSnippet from "@/components/utils/MetaPixelSnippet";
import HowItWorks from "@/components/signUp/HowItWorks";
import CostComparisonSection from "@/components/signUp/CostComparisonSection";
import CustomClerkForm from "@/components/signUp/CustomClerkForm";
import PoweredBySaltboxBadge from "@/components/signUp/PoweredBySaltboxBadge";
import { Text } from "../foundation/Text";

export default function SharedAuthPage() {
  return (
    <div className="relative flex min-h-[100vh] w-full min-w-[400px] flex-col items-center justify-center gap pt-1600">
      <MetaPixelSnippet />
      <div
        className={`fixed left-0 right-0 top-0 z-100 flex w-full select-none flex-row items-center justify-between border-b border-dark bg-[#F6F4EE] p-4`}
      >
        <Image
          priority
          src={parselLogo}
          alt="parsel logo"
          className="w-[80px]"
        />
        <PoweredBySaltboxBadge />
      </div>
      <div className="flex h-full w-full flex-col items-center gap-sm pt-20">
        <div className="z-50 -mb-1600 w-full max-w-[400px] px-4">
          <CustomClerkForm />
        </div>
        <div className="flex h-full w-full flex-col justify-between bg-brand-yellow">
          <div className="px-4 py-28">
            <CostComparisonSection />
          </div>
          <div className="flex flex-row p-4">
            <Text variant="subheadingSm">
              © 2024 Parsel. All rights reserved.
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
}
