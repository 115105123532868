import { PoweredBySaltboxLogo } from "@/public/images/signUp";
import { OreganoText } from "../foundation";

export default function PoweredBySaltboxBadge() {
  return (
    <a href="https://www.saltbox.com/?source=parsel" target="_blank">
      <div className="flex select-none flex-row items-center rounded-full border bg-white p-xs">
        <OreganoText variant="headingSm">Powered by</OreganoText>
        <div className="pb-50 pl-100">
          <PoweredBySaltboxLogo />
        </div>
      </div>
    </a>
  );
}
