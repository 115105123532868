import { BoxIllustration } from "@/public/images/signUp";
import { OreganoIcon, OreganoText } from "../foundation";
import { ComparisonWidget } from "./ComparisonWidget";

const CostComparisonSection = () => {
  return (
    <div className="flex flex-col gap ">
      <div className="flex flex-col items-center gap-sm">
        <OreganoIcon size="2xl">
          <BoxIllustration />
        </OreganoIcon>
        <OreganoText variant="heading2xl" className="text-center">
          Rates that are almost too good to be true
        </OreganoText>
      </div>
      <ComparisonWidget />
    </div>
  );
};

export default CostComparisonSection;
