import {
  isClerkAPIResponseError,
  useClerk,
  useSignIn,
  useSignUp,
} from "@clerk/nextjs";
import { Button } from "../foundation/Button";
import { useForm } from "react-hook-form";
import { OreganoIcon, OreganoText, OreganoTextField } from "../foundation";
import { TextField } from "../foundation/TextField";
import { HookFormTextField } from "../foundation/HookFormTextField/HookFormTextField";
import {
  EmailLinkFactor,
  SetActive,
  SignInResource,
  SignUpResource,
} from "@clerk/types";
import { useCallback, useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import { LoadingSpinner } from "../LoadingSpinner";
import toast from "react-hot-toast";
import Image from "next/image";
import { GoogleIcon } from "@/public/images/signUp";
import { Text } from "../foundation/Text";
import { Divider } from "../foundation/Divider";
import Link from "next/link";
import parselLogo from "@/public/parsel_logo_black.png";
import PoweredBySaltboxBadge from "./PoweredBySaltboxBadge";
import {
  ArrowRightIcon,
  CircleExclamationIcon,
  CircleInfoIcon,
  InfoIcon,
  PartyHornIcon,
  PenToSquareIcon,
} from "@/public/icons/solid";
import { Badge } from "../foundation/Badge";

interface LoadedCustomClerkFormProps {
  signIn: SignInResource;
  signUp: SignUpResource;
  setSignInResource: (resource: SignInResource) => void;
  handlePostAuthRedirect: () => void;
}

interface CustomClerkFormData {
  emailAddress: string;
}

function LoadedCustomClerkForm({
  signIn,
  signUp,
  setSignInResource,
  handlePostAuthRedirect,
}: LoadedCustomClerkFormProps) {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CustomClerkFormData>();
  const router = useRouter();
  const { signOut } = useClerk();

  const [googleSignInLoading, setGoogleSignInLoading] = useState(false);
  const [signInLoading, setSignInLoading] = useState(false);

  const handleGoogleSignIn = async () => {
    if (signIn != null) {
      setGoogleSignInLoading(true);
      await signIn.authenticateWithRedirect({
        strategy: "oauth_google",
        redirectUrl: "/auth/oauth-callback",
        redirectUrlComplete: "/",
      });
    }
  };

  const handleFormData = async (data: CustomClerkFormData) => {
    try {
      setSignInLoading(true);
      let result: SignInResource | undefined = undefined;
      try {
        result = await signIn.create({
          identifier: data.emailAddress,
        });
      } catch (e) {
        if (isClerkAPIResponseError(e)) {
          if (e.errors[0].code === "form_identifier_not_found") {
            const signUpResult = await signUp.create({
              emailAddress: data.emailAddress,
            });

            await signOut();

            if (signUpResult.status === "complete") {
              result = await signIn.create({
                identifier: data.emailAddress,
              });
            }
          } else if (e.errors[0].code === "session_exists") {
            handlePostAuthRedirect();
          } else {
            throw e;
          }
        } else {
          throw e;
        }
      }

      if (result?.status === "needs_first_factor") {
        setSignInResource(result);
      }
    } catch (e) {
      toast.error("Whoops, that didn't work.");
    } finally {
      setSignInLoading(false);
    }
  };

  return (
    <div className="flex h-full w-full flex-col gap-lg">
      <div className="flex flex-col gap-lg">
        <div className="flex flex-col gap-xs"></div>
        <Button
          loading={googleSignInLoading}
          disabled={signInLoading}
          onClick={handleGoogleSignIn}
          variant="outline"
        >
          <div className="flex w-full select-none flex-row items-center gap-sm">
            <GoogleIcon />
            <Text variant="headingSm">Sign in with Google</Text>
          </div>
        </Button>
        <div className="flex select-none flex-row items-center gap-sm">
          <Divider className="w-full" />
          <Text>or</Text>
          <Divider className="w-full" />
        </div>
        <form
          onSubmit={handleSubmit(handleFormData)}
          className="flex flex-col gap-sm"
        >
          <label className="flex flex-1 flex-col gap-100">
            <Text variant="headingSm">Email address</Text>
            <HookFormTextField
              variant="login"
              control={control}
              {...register("emailAddress", { required: true })}
              disabled={googleSignInLoading || signInLoading}
              autoCapitalize="none"
              autoCorrect="off"
              spellCheck="false"
            />
          </label>
          <div className="flex flex-col gap-xs">
            <Button
              disabled={googleSignInLoading}
              loading={signInLoading}
              type="submit"
            >
              Continue
            </Button>
            {/* <div className="flex flex-row gap-sm">
              <OreganoIcon fill="info" size="sm">
                <CircleInfoIcon />
              </OreganoIcon>
              <Text color="info">We sent a sign-in link to your email.</Text>
            </div> */}
            <div className="flex flex-col">
              <Text variant="bodySm" color="subdued">
                By continuing, you agree to Parsel's{" "}
                <Link href="/terms-of-service" className="underline">
                  terms of service.
                </Link>
              </Text>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

interface CheckEmailWidgetProps {
  handleGoBack: () => void;
  signInResource: SignInResource;
  setActive: SetActive;
  handlePostAuthRedirect: () => void;
}

function CheckEmailWidget({
  handleGoBack,
  signInResource: result,
  setActive,
  handlePostAuthRedirect,
}: CheckEmailWidgetProps) {
  // supportedFirstFactors can be null after verification
  const linkFactor = result?.supportedFirstFactors?.find(
    (f) => f.strategy === "email_link",
  ) as EmailLinkFactor | null;

  const numTriggers = useRef(0);
  const [expired, setExpired] = useState(false);
  const [allowResend, setAllowResend] = useState(false);

  const trigger = async () => {
    if (linkFactor != null) {
      /**
       * This handles an edge case where the user resends a link, and the old link expires. We don't want to setExpired for the old link.
       */
      numTriggers.current += 1;
      const triggerId = numTriggers.current;

      setAllowResend(false);
      setExpired(false);
      setTimeout(() => setAllowResend(true), 10000);
      const flow = result.createEmailLinkFlow();
      const started = await flow.startEmailLinkFlow({
        emailAddressId: linkFactor.emailAddressId,
        redirectUrl: `${window.location.protocol}//${window.location.host}/auth/email-link-verification`,
      });

      // Check the verification result.
      const verification = started.firstFactorVerification;
      if (
        verification.status === "expired" &&
        triggerId === numTriggers.current
      ) {
        console.log("setting expired");
        setAllowResend(false);
        setExpired(true);
      }

      if (started.status === "complete") {
        await setActive({
          session: started.createdSessionId,
        });
        handlePostAuthRedirect();
        return;
      }
    }
  };

  const emailAutoTriggered = useRef(false);
  useEffect(() => {
    if (!emailAutoTriggered.current) {
      trigger();
    }

    emailAutoTriggered.current = true;
  }, []);

  return (
    <div className="flex flex-col gap">
      <div className="flex flex-col gap-xs">
        <Text variant="headingLg">Check your email</Text>
        <Text variant="bodyLg">to continue to Parsel</Text>
      </div>
      <div className="flex flex-row gap-sm self-start rounded-full border border-dark bg-corrugated-50 p-sm">
        <Text className="max-w-[220px] overflow-hidden text-ellipsis">
          {linkFactor?.safeIdentifier}
        </Text>
        <div className="cursor-pointer" onClick={handleGoBack}>
          <OreganoIcon size="sm" fill="info">
            <PenToSquareIcon />
          </OreganoIcon>
        </div>
      </div>
      <div className="flex flex-col gap-xs">
        <Text variant="headingSm">Verification link</Text>
        {expired ? (
          <div
            className="flex cursor-pointer flex-row gap-xs"
            onClick={trigger}
          >
            <OreganoIcon size="sm" fill="critical">
              <CircleExclamationIcon />
            </OreganoIcon>
            <Text color="critical">
              The verification link expired. Click here to request a new link.
            </Text>
          </div>
        ) : (
          <>
            <Text variant="bodyMd">
              Use the verification link sent to your email
            </Text>
            {allowResend && (
              <div className="cursor-pointer" onClick={trigger}>
                <Text color="info">Didn't get a link? Resend</Text>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default function CustomClerkForm() {
  const { user, loaded } = useClerk();
  const { isLoaded: signInLoaded, signIn, setActive } = useSignIn();
  const { isLoaded: signUpLoaded, signUp } = useSignUp();
  const router = useRouter();

  const [signInResource, setSignInResource] = useState<
    SignInResource | undefined
  >();

  const handlePostAuthRedirect = () => {
    const redirectUrl = router.query["redirect_url"];
    if (redirectUrl != null && typeof redirectUrl === "string") {
      window.location.href = redirectUrl;
    } else {
      window.location.href = "/";
    }
  };

  let content = (
    <div className="flex h-[200px] w-[300px] flex-col items-center justify-center">
      <LoadingSpinner />
    </div>
  );
  if (user != null) {
    window.location.href = "/";
  } else if (loaded && signInLoaded && signUpLoaded) {
    content = (
      <LoadedCustomClerkForm
        signIn={signIn}
        signUp={signUp}
        setSignInResource={setSignInResource}
        handlePostAuthRedirect={handlePostAuthRedirect}
      />
    );
  }
  const handleGoBack = () => {
    setSignInResource(undefined);
  };

  return (
    <div className="flex w-full max-w-[400px] flex-col gap-lg rounded-lg bg-white p-10 shadow-2xl">
      {signInResource != null ? (
        <CheckEmailWidget
          handleGoBack={handleGoBack}
          signInResource={signInResource}
          setActive={setActive!}
          handlePostAuthRedirect={handlePostAuthRedirect}
        />
      ) : (
        <div>
          <div className="flex flex-col gap-xs">
            <Text variant="headingLg">Continue to Parsel</Text>
            {/* <Badge status="success">Bop</Badge> */}
            <div className="flex flex-row items-center gap-xs rounded-sm">
              <OreganoIcon size="sm" fill="success">
                <PartyHornIcon />
              </OreganoIcon>
              <OreganoText color="success">
                {/* Get 5 free labels and 3 free pickups for signing up! */}
                Workspace members get $50 in shipping credits for signing up!
              </OreganoText>
            </div>
          </div>
          {content}
        </div>
      )}
      <div className="flex flex-row gap">
        <Link href="/privacy-policy">
          <Text className="hover:underline">Privacy</Text>
        </Link>
        <Link href="/privacy-policy">
          <Text className="hover:underline">Terms</Text>
        </Link>
      </div>
    </div>
  );
}
